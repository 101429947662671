<template>
	<div class="chat-container">
		<div class="chat-title">
			<img :src="customer.avatar" class="chat-avatar" />
			<div class="chat-name">{{ customer.name }}</div>
		</div>
		<div class="chat-main" ref="scrollView">
			<div class="message-list" ref="messageList">
				<div v-if="history.loading" class="history-loading">
					<img src="../assets/images/pending.gif" />
				</div>
				<div v-else :class="history.loaded ? 'history-loaded':'load'" @click="loadHistoryMessage(false)">
					{{ history.loaded ? '已经没有更多的历史消息' : '获取历史消息' }}
				</div>
				<div v-for="(message, index) in history.messages" :key="index">
					<div class="time-tips">{{ renderMessageDate(message, index) }}</div>
					<div class="message-item">
						<div v-if="message.type === 'CS_ACCEPT'" class="accept-message">
							{{ message.senderData.name }}已接入
						</div>
						<div v-else-if="message.type === 'CS_END'" class="accept-message">
							{{ message.senderData.name }}已结束会话
						</div>
						<div v-else-if="message.type === 'CS_TRANSFER'" class="accept-message">
							{{
                message.senderId === currentAgent.id ? `已转接给` + message.payload.transferTo.data.name :
                  '已接入来自' + message.senderData.name + '的转接'
              }}
						</div>
						<div v-else :class="{ self: message.senderId !== customer.id }" class="message-item-content">
							<div class="sender-info">
								<img :src="message.senderData.avatar" class="sender-avatar" @click="showInfo(message.senderId)"/>
								<div class="sender-name">
									{{ message.senderData.name }}
								</div>
							</div>
							<div class="message-content">
								<div class="message-payload">
									<div v-if="message.status === 'sending'" class="pending"></div>
									<div v-if="message.status === 'fail'" class="send-fail"></div>
									<div v-if="message.type === 'text'" class="content-text"
										v-html="renderTextMessage(message.payload.text)"></div>
									<div v-if="message.type === 'image'" class="content-image"
										@click="showImagePreviewPopup(message.payload.url)">
										<img :src="message.payload.url"
											:style="{height:getImageHeight(message.payload.width,message.payload.height)+'px'}" />
									</div>
									<div v-if="message.type ==='audio'" class="content-audio"
										@click="playAudio(message)">
										<!-- :style="{width:Math.ceil(message.payload.duration)*7 + 50 + 'px'}" -->
										<div class="audio-facade"
											>
											<div class="audio-facade-bg"
												:class="{'play-icon':audioPlayer.playingMessage === message}"></div>
											<div>{{ Math.ceil(message.payload.duration) || 1 }}<span>"</span></div>
										</div>
									</div>
									<goeasy-video-player v-if="message.type === 'video'"
										:src="message.payload.video.url" :thumbnail="message.payload.thumbnail" />
									<div v-if="message.type === 'order'" class="content-order">
										<div class="order-id">订单号：{{ message.payload.id }}</div>
										<div class="order-body">
											<img :src="message.payload.url" class="order-img" />
											<div class="order-name">{{ message.payload.name }}</div>
											<div>
												<div class="order-price">{{ message.payload.price }}</div>
												<div class="order-count">共{{ message.payload.count }}件</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="chat-footer">
			<div v-if="customerStatus==null" class="accept-session">
				<div>离线中</div>
			</div>
			<div v-else-if="customerStatus.status==='PENDING'" class="accept-session">
				<div class="accept-info">
					会话已等待{{ pendingTime.duration }}
				</div>
				<button class="accept-btn" @click="acceptSession">立即接入</button>
			</div>
			<div v-else-if="customerStatus.status==='ACCEPTED' && currentAgent.id !== customerStatus.agent.id"
				class="accept-session">
				<div class="accept-info">{{ customerStatus.agent.data.name }}已接入</div>
			</div>
			<div v-else-if="customerStatus.status==='FREE'" class="accept-session">
				<button class="accept-btn" @click="acceptSession">主动接入</button>
			</div>
			<div v-else class="action-box">
				<div class="action-bar">
					<div class="chat-action">
						<!-- 表情 -->
						<div class="action-item">
							<div v-if="emoji.visible" class="emoji-box">
								<img v-for="(emojiItem, emojiKey, index) in emoji.map" :key="index"
									:src="emoji.url + emojiItem" class="emoji-item" @click="chooseEmoji(emojiKey)" />
							</div>
							<i class="iconfont icon-smile" title="表情" @click="showEmojiBox"></i>
							<div v-if="emoji.visible"  @click="close_em" style="width: 30px;height: 30px;color: #000000;line-height: 30px;text-align: center;border-radius: 50%;border: 1px solid #000000;position: relative;top: -140px;left: 530px;-webkit-transform: translate(-50%, -50%);transform: translate(-50%, -50%);z-index: 999999999999;">X</div>
						</div>
						<!-- 图片 -->
						<div class="action-item">
							<label for="img-input">
								<i class="iconfont icon-picture" title="图片"></i>
							</label>
							<input v-show="false" id="img-input" accept="image/*" multiple type="file"
								@change="sendImageMessage" />
						</div>
						<!-- 视频 -->
						<div class="action-item">
							<label for="video-input"><i class="iconfont icon-film" title="视频"></i></label>
							<input v-show="false" id="video-input" accept="video/*" type="file"
								@change="sendVideoMessage" />
						</div>
						<!-- 商品链接 -->
						<!-- <div class="action-item">
							<i class="iconfont icon-liebiao" title="订单" @click="showOrderMessageList"></i>
						</div> -->
					</div>
					<div class="session-action">
						<span class="transfer" @click="showTransferForm()">转接</span>
						<span class="transfer" @click="endSession()">结束会话</span>
					</div>
				</div>
				<div class="input-box">
					<textarea ref="input" @focus="onInputFocus" @keyup.enter="sendTextMessage" v-model="text"
						autocomplete="off" class="input-content"></textarea>
				</div>
				<div class="send-box">
					<button class="send-button" @click="sendTextMessage">发送</button>
				</div>
			</div>
		</div>
		<!-- 语音播放器 -->
		<audio ref="audioPlayer" @ended="onAudioPlayEnd" @pause="onAudioPlayEnd"></audio>
		<!-- 图片预览弹窗 -->
		<div v-if="imagePopup.visible" class="image-preview">
			<img :src="imagePopup.url" alt="图片" />
			<span class="close" @click="hideImagePreviewPopup">×</span>
		</div>
		<!-- 转接弹窗 -->
		<div v-if="transferForm.visible" class="transfer-popup">
			<div class="transfer-model">
				<div v-if="transferForm.agents.length" class="transfer-content">
					<div v-for="(agent, index) in transferForm.agents" class="agent-info">
						<label class="agent-label">
							<input v-model="transferForm.to" :name="agent.data.name" :value="agent" type="radio" />
							<img :src="agent.data.avatar" class="agent-avatar" />
							<span class="agent-name">{{ agent.data.name }}</span>
						</label>
					</div>
				</div>
				<div v-else class="transfer-content">
					<div class="no-agent">-当前无其他客服在线-</div>
				</div>
				<div class="transfer-bottom">
					<span v-if="transferForm.agents.length" class="transfer-button" @click="transfer()">确认</span>
					<span class="transfer-button" @click="hideTransferForm()">取消</span>
				</div>
			</div>
		</div>
		<!-- 订单弹窗 -->
		<div v-if="tanchuang" class="order-box">
			<div class="order-list">
				<div class="title">
				  <span @click="closetanchuang">×</span>
				</div>
				<div class="order-item">
					<div class="order-id">用户id：{{uid}}</div>
					<div class="order-body">
						<img :src="avatar" class="order-img" />
						<div class="order-name">手机号：{{phone}}</div>
						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		formatDate,
		formateTime
	} from '../utils/utils.js'
	import restApi from '../api/restapi';
	import EmojiDecoder from '../utils/EmojiDecoder';
	import GoeasyVideoPlayer from "../components/GoEasyVideoPlayer";
	import axios from 'axios';
	const IMAGE_MAX_WIDTH = 200;
	const IMAGE_MAX_HEIGHT = 150;
	export default {
		name: "Chat",
		components: {
			GoeasyVideoPlayer,
		},
		data() {
			const emojiUrl = 'https://imgcache.qq.com/open/qcloud/tim/assets/emoji/';
			const emojiMap = {
				'[NO]': 'emoji_0@2x.png',
				'[OK]': 'emoji_1@2x.png',
				'[下雨]': 'emoji_2@2x.png',
				'[么么哒]': 'emoji_3@2x.png',
				'[乒乓]': 'emoji_4@2x.png',
				'[便便]': 'emoji_5@2x.png',
				'[信封]': 'emoji_6@2x.png',
				'[偷笑]': 'emoji_7@2x.png',
				'[傲慢]': 'emoji_8@2x.png',
				'[再见]': 'emoji_9@2x.png',
				'[冷汗]': 'emoji_10@2x.png',
				'[凋谢]': 'emoji_11@2x.png',
				'[刀]': 'emoji_12@2x.png',
				'[删除]': 'emoji_13@2x.png',
				'[勾引]': 'emoji_14@2x.png',
				'[发呆]': 'emoji_15@2x.png',
				'[发抖]': 'emoji_16@2x.png',
				'[可怜]': 'emoji_17@2x.png',
				'[可爱]': 'emoji_18@2x.png',
				'[右哼哼]': 'emoji_19@2x.png',
				'[右太极]': 'emoji_20@2x.png',
				'[右车头]': 'emoji_21@2x.png',
				'[吐]': 'emoji_22@2x.png',
				'[吓]': 'emoji_23@2x.png',
				'[咒骂]': 'emoji_24@2x.png',
				'[咖啡]': 'emoji_25@2x.png',
				'[啤酒]': 'emoji_26@2x.png',
				'[嘘]': 'emoji_27@2x.png',
				'[回头]': 'emoji_28@2x.png',
				'[困]': 'emoji_29@2x.png',
				'[坏笑]': 'emoji_30@2x.png',
				'[多云]': 'emoji_31@2x.png',
				'[大兵]': 'emoji_32@2x.png',
				'[大哭]': 'emoji_33@2x.png',
				'[太阳]': 'emoji_34@2x.png',
				'[奋斗]': 'emoji_35@2x.png',
				'[奶瓶]': 'emoji_36@2x.png',
				'[委屈]': 'emoji_37@2x.png',
				'[害羞]': 'emoji_38@2x.png',
				'[尴尬]': 'emoji_39@2x.png',
				'[左哼哼]': 'emoji_40@2x.png',
				'[左太极]': 'emoji_41@2x.png',
				'[左车头]': 'emoji_42@2x.png',
				'[差劲]': 'emoji_43@2x.png',
				'[弱]': 'emoji_44@2x.png',
				'[强]': 'emoji_45@2x.png',
				'[彩带]': 'emoji_46@2x.png',
				'[彩球]': 'emoji_47@2x.png',
				'[得意]': 'emoji_48@2x.png',
				'[微笑]': 'emoji_49@2x.png',
				'[心碎了]': 'emoji_50@2x.png',
				'[快哭了]': 'emoji_51@2x.png',
				'[怄火]': 'emoji_52@2x.png',
				'[怒]': 'emoji_53@2x.png',
				'[惊恐]': 'emoji_54@2x.png',
				'[惊讶]': 'emoji_55@2x.png',
				'[憨笑]': 'emoji_56@2x.png',
				'[手枪]': 'emoji_57@2x.png',
				'[打哈欠]': 'emoji_58@2x.png',
				'[抓狂]': 'emoji_59@2x.png',
				'[折磨]': 'emoji_60@2x.png',
				'[抠鼻]': 'emoji_61@2x.png',
				'[抱抱]': 'emoji_62@2x.png',
				'[抱拳]': 'emoji_63@2x.png',
				'[拳头]': 'emoji_64@2x.png',
				'[挥手]': 'emoji_65@2x.png',
				'[握手]': 'emoji_66@2x.png',
				'[撇嘴]': 'emoji_67@2x.png',
				'[擦汗]': 'emoji_68@2x.png',
				'[敲打]': 'emoji_69@2x.png',
				'[晕]': 'emoji_70@2x.png',
				'[月亮]': 'emoji_71@2x.png',
				'[棒棒糖]': 'emoji_72@2x.png',
				'[汽车]': 'emoji_73@2x.png',
				'[沙发]': 'emoji_74@2x.png',
				'[流汗]': 'emoji_75@2x.png',
				'[流泪]': 'emoji_76@2x.png',
				'[激动]': 'emoji_77@2x.png',
				'[灯泡]': 'emoji_78@2x.png',
				'[炸弹]': 'emoji_79@2x.png',
				'[熊猫]': 'emoji_80@2x.png',
				'[爆筋]': 'emoji_81@2x.png',
				'[爱你]': 'emoji_82@2x.png',
				'[爱心]': 'emoji_83@2x.png',
				'[爱情]': 'emoji_84@2x.png',
				'[猪头]': 'emoji_85@2x.png',
				'[猫咪]': 'emoji_86@2x.png',
				'[献吻]': 'emoji_87@2x.png',
				'[玫瑰]': 'emoji_88@2x.png',
				'[瓢虫]': 'emoji_89@2x.png',
				'[疑问]': 'emoji_90@2x.png',
				'[白眼]': 'emoji_91@2x.png',
				'[皮球]': 'emoji_92@2x.png',
				'[睡觉]': 'emoji_93@2x.png',
				'[磕头]': 'emoji_94@2x.png',
				'[示爱]': 'emoji_95@2x.png',
				'[礼品袋]': 'emoji_96@2x.png',
				'[礼物]': 'emoji_97@2x.png',
				'[篮球]': 'emoji_98@2x.png',
				'[米饭]': 'emoji_99@2x.png',
				'[糗大了]': 'emoji_100@2x.png',
				'[红双喜]': 'emoji_101@2x.png',
				'[红灯笼]': 'emoji_102@2x.png',
				'[纸巾]': 'emoji_103@2x.png',
				'[胜利]': 'emoji_104@2x.png',
				'[色]': 'emoji_105@2x.png',
				'[药]': 'emoji_106@2x.png',
				'[菜刀]': 'emoji_107@2x.png',
				'[蛋糕]': 'emoji_108@2x.png',
				'[蜡烛]': 'emoji_109@2x.png',
				'[街舞]': 'emoji_110@2x.png',
				'[衰]': 'emoji_111@2x.png',
				'[西瓜]': 'emoji_112@2x.png',
				'[调皮]': 'emoji_113@2x.png',
				'[象棋]': 'emoji_114@2x.png',
				'[跳绳]': 'emoji_115@2x.png',
				'[跳跳]': 'emoji_116@2x.png',
				'[车厢]': 'emoji_117@2x.png',
				'[转圈]': 'emoji_118@2x.png',
				'[鄙视]': 'emoji_119@2x.png',
				'[酷]': 'emoji_120@2x.png',
				'[钞票]': 'emoji_121@2x.png',
				'[钻戒]': 'emoji_122@2x.png',
				'[闪电]': 'emoji_123@2x.png',
				'[闭嘴]': 'emoji_124@2x.png',
				'[闹钟]': 'emoji_125@2x.png',
				'[阴险]': 'emoji_126@2x.png',
				'[难过]': 'emoji_127@2x.png',
				'[雨伞]': 'emoji_128@2x.png',
				'[青蛙]': 'emoji_129@2x.png',
				'[面条]': 'emoji_130@2x.png',
				'[鞭炮]': 'emoji_131@2x.png',
				'[风车]': 'emoji_132@2x.png',
				'[飞吻]': 'emoji_133@2x.png',
				'[飞机]': 'emoji_134@2x.png',
				'[饥饿]': 'emoji_135@2x.png',
				'[香蕉]': 'emoji_136@2x.png',
				'[骷髅]': 'emoji_137@2x.png',
				'[麦克风]': 'emoji_138@2x.png',
				'[麻将]': 'emoji_139@2x.png',
				'[鼓掌]': 'emoji_140@2x.png',
				'[龇牙]': 'emoji_141@2x.png'
			};
			return {
				currentAgent: null,
				csteam: null,
				customer: null,
				customerStatus: null,

				to: {}, //用于创建消息时传入

				history: {
					messages: [],
					loaded: false,
					loading: true
				},

				text: '',
				emoji: {
					url: emojiUrl,
					map: emojiMap,
					visible: false,
					decoder: new EmojiDecoder(emojiUrl, emojiMap),
				},
				imagePopup: {
					visible: false,
					url: ''
				},
				audioPlayer: {
					playingMessage: null,
				},
				orderList: {
					orders: [],
					visible: false,
				},
				transferForm: {
					visible: false,
					agents: [],
					to: {}
				},
				pendingTime: {
					timer: null,
					duration: ''
				},
				tanchuang:false,
				account:null,
				phone:null,
				uid:null,
				avatar:null
			}
		},
		created() {
			this.customer = {
				id: this.$route.params.id,
				name: this.$route.query.name,
				avatar: this.$route.query.avatar,
			};
			console.log(this.customer);
			this.to = {
				type: this.GoEasy.IM_SCENE.CS,
				id: this.customer.id,
				data: {
					name: this.customer.name,
					avatar: this.customer.avatar
				},
			};
			this.currentAgent = this.globalData.currentAgent;
			console.log(this.currentAgent.shopId)
			this.csteam = this.goEasy.im.csteam(this.currentAgent.shopId);

			this.listenCustomer();
			this.loadHistoryMessage(true);
		},
		beforeDestroy() {
			this.csteam.cancelListenCustomer({
				onSuccess: () => {
					console.log('cancelListenCustomer successfully11111111 ');
				},
				onFailed: (error) => {
					console.log('failed to cancelListenCustomer:', error);
				},
			});
			if (this.pendingTime.timer) {
				clearInterval(this.pendingTime.timer);
			}
		},
		methods: {
			async showInfo(uid){
				const { data: res } = await axios.post('https://www.jingtaotao.shop/api/userinfokf',{uid: uid})
				this.tanchuang =true;
				this.uid= res.data.uid;
				this.account= res.data.account;
				this.phone =res.data.phone;
				this.avatar =res.data.avatar ? res.data.avatar : 'https://www.jingtaotao.shop/mer/img/f.5aa43cd3.png';
			},
			closetanchuang(){
				this.tanchuang =false;
			},
			close_em() {
				this.emoji.visible = false;
			},
			renderTextMessage(text) {
				return this.emoji.decoder.decode(text);
			},
			listenCustomer() {
				console.log(this.customer.id)
				this.csteam.listenCustomer({
					id: this.customer.id,
					onSuccess: () => {
						console.log('listen customer successfully11111111');
						this.markMessageAsRead();
					},
					onFailed: (error) => {
						console.log('failed to listen customer:', error);
					},
					onStatusUpdated: (customerStatus) => {
						this.customerStatus = customerStatus;
						if (customerStatus.status === 'PENDING') {
							this.updatePendingTime(customerStatus.start);
						}
					},
					onNewMessage: (message) => {
						this.onReceivedMessage(message);
					},
				})
			},
			updatePendingTime(time) {
				this.pendingTime.duration = formateTime(time);
				clearInterval(this.pendingTime.timer);
				this.pendingTime.timer = setInterval(() => {
					this.pendingTime.duration = formateTime(time);
				}, 1000);
			},
			onReceivedMessage(newMessage) {
				//如果该消息已存在，跳过
				if (this.history.messages.findIndex((message) => newMessage.id === message.messageId) >= 0) {
					return;
				}
				this.history.messages.push(newMessage);
				this.markMessageAsRead();
				this.scrollToBottom();
			},
			markMessageAsRead() {
				let that = this
				this.csteam.markMessageAsRead({
					type: this.GoEasy.IM_SCENE.CS,
					id: this.customer.id,
					onSuccess: function() {
						that.$notify.setTitle() // 取消闪烁
						console.log('标记已读成功');
					},
					onFailed: function(error) {
						console.log('标记已读失败', error);
					}
				});
			},
			loadHistoryMessage(scrollToBottom) {
				this.history.loading = true;
				let lastMessageTimeStamp;
				let lastMessage = this.history.messages[0];
				if (lastMessage) {
					lastMessageTimeStamp = lastMessage.timestamp;
				}
				let limit = 10;
				this.csteam.history({
					id: this.customer.id,
					type: this.GoEasy.IM_SCENE.CS,
					lastTimestamp: lastMessageTimeStamp,
					limit: limit,
					onSuccess: (result) => {
						this.history.loading = false;
						let messages = result.content;
						if (messages.length === 0) {
							this.history.loaded = true;
						} else {
							if (lastMessageTimeStamp) {
								this.history.messages = messages.concat(this.history.messages);
							} else {
								this.history.messages = messages;
							}
							if (messages.length < 10) {
								this.history.allLoaded = true;
							}
							if (scrollToBottom) {
								this.scrollToBottom();
							}
						}
					},
					onFailed: (error) => {
						//获取失败
						this.history.loading = false;
						console.log('获取历史消息失败, code:' + error.code + ',错误信息:' + error.content);
					},
				});
			},
			/**
			 * 核心就是设置高度，产生明确占位
			 *
			 * 小  (宽度和高度都小于预设尺寸)
			 *    设高=原始高度
			 * 宽 (宽度>高度)
			 *    高度= 根据宽度等比缩放
			 * 窄  (宽度<高度)或方(宽度=高度)
			 *    设高=MAX height
			 *
			 * @param width,height
			 * @returns number
			 */
			getImageHeight(width, height) {
				if (width < IMAGE_MAX_WIDTH && height < IMAGE_MAX_HEIGHT) {
					return height;
				} else if (width > height) {
					return IMAGE_MAX_WIDTH / width * height;
				} else if (width === height || width < height) {
					return IMAGE_MAX_HEIGHT;
				}
			},
			playAudio(audioMessage) {
				let playingMessage = this.audioPlayer.playingMessage;

				if (playingMessage) {
					this.$refs.audioPlayer.pause();
					// 如果点击的消息正在播放，就认为是停止播放操作
					if (playingMessage === audioMessage) {
						return;
					}
				}

				this.audioPlayer.playingMessage = audioMessage;
				this.$refs.audioPlayer.src = audioMessage.payload.url;
				this.$refs.audioPlayer.load();
				this.$refs.audioPlayer.currentTime = 0;
				this.$refs.audioPlayer.play();
			},
			onAudioPlayEnd() {
				this.audioPlayer.playingMessage = null;
			},
			renderMessageDate(message, index) {
				if (index === 0) {
					return formatDate(message.timestamp);
				} else {
					if (message.timestamp - this.history.messages[index - 1].timestamp > 5 * 60 * 1000) {
						return formatDate(message.timestamp);
					}
				}
				return '';
			},
			showImagePreviewPopup(url) {
				this.imagePopup.visible = true;
				this.imagePopup.url = url;
			},
			hideImagePreviewPopup() {
				this.imagePopup.visible = false;
			},

			isOnline() {
				return new Promise((resolve, reject) => {
					this.csteam.isOnline({
						onSuccess: (result) => {
							resolve(result);
						},
						onFailed: (error) => {
							console.log('获取在线状态失败，error:', error)
							reject(error);
						}
					})
				})
			},


			async acceptSession() {
				if (await this.isOnline()) {
					this.csteam.accept({
						customer: {
							id: this.customer.id,
							data: {
								name: this.customer.name,
								avatar: this.customer.avatar
							}
						},
						onSuccess: () => {
							console.log('accept successfully.');
							clearInterval(this.pendingTime.timer);
						},
						onFailed: (error) => {
							console.log('accept failed', error);
						}
					});
				} else {
					alert('您还不是一名该团队的在线客服，请点击左下角头像进行上线操作')
				}
			},
			endSession() {
				this.csteam.end({
					id: this.customer.id,
					onSuccess: () => {
						console.log('end successfully.');
					},
					onFailed: (error) => {
						console.log('end failed', error);
					}
				})
			},
			showTransferForm() {
				this.csteam.agents({
					onSuccess: (result) => {
						this.transferForm.visible = true;
						this.transferForm.agents = result.content.filter((agent) => {
							return agent.id !== this.currentAgent.id;
						});
					},
					onFailed: (error) => {
						console.log('query online agents failed', error);
					}
				});
			},
			transfer() {
				this.csteam.transfer({
					customerId: this.customer.id,
					agentId: this.transferForm.to.id,
					onSuccess: () => {
						this.transferForm.visible = false;
						console.log('transfer successfully.');
					},
					onFailed: (error) => {
						console.log('transfer failed', error);
					}
				})
			},
			hideTransferForm() {
				this.transferForm.visible = false;
			},
			onInputFocus() {
				this.emoji.visible = false;
			},
			showEmojiBox() {
				this.emoji.visible = !this.emoji.visible;
			},
			chooseEmoji(emojiKey) {
				this.text += emojiKey;
				this.emoji.visible = false;
			},
			sendTextMessage() {
				if (this.text.trim().length === 0) {
					console.log('输入为空');
					return
				}
				this.csteam.createTextMessage({
					text: this.text,
					to: this.to,
					onSuccess: (message) => {
						this.sendMessage(message);
						this.text = '';
					},
					onFailed: (err) => {
						console.log("创建消息err:", err);
					}
				});
			},
			sendImageMessage(e) {
				let fileList = [...e.target.files];
				fileList.forEach((file) => {
					this.csteam.createImageMessage({
						file: file,
						to: this.to,
						onProgress: function(progress) {
							console.log(progress)
						},
						onSuccess: (message) => {
							this.sendMessage(message);
						},
						onFailed: (e) => {
							console.log('error :', e);
						}
					});
				})
			},
			sendVideoMessage(e) {
				const file = e.target.files[0];
				this.csteam.createVideoMessage({
					file: file,
					to: this.to,
					onProgress: function(progress) {
						console.log(progress)
					},
					onSuccess: (message) => {
						this.sendMessage(message);
					},
					onFailed: (e) => {
						console.log('error :', e);
					}
				});
			},
			closeOrderMessageList() {
				this.orderList.visible = false;
			},
			async showOrderMessageList() {
			this.orderList.orders = restApi.getOrderList();
				// let user_id = this.customer.id;
				// if(user_id){
				// 	const { data: res } = await axios.post('https://www.jingtaotao.shop/api/userOrder',{user_id: this.customer.id})
				// 	console.log(res);
				// }

				
				
				
				this.orderList.visible = true;
			},
			sendOrderMessage(order) {
				this.orderList.visible = false;
				this.csteam.createCustomMessage({
					type: 'order',
					payload: order,
					to: this.to,
					onSuccess: (message) => {
						this.sendMessage(message);
					},
					onFailed: (err) => {
						console.log("创建消息err:", err);
					}
				});
			},
			sendMessage(message) {
				this.history.messages.push(message);
				this.scrollToBottom();
				this.goEasy.im.sendMessage({
					message: message,
					onSuccess: (message) => {
						console.log('发送成功', message);
					},
					onFailed: function(error) {
						if (error.code === 507) {
							alert('发送语音/图片/视频/文件失败，没有配置OSS存储');
							console.log(
								'发送语音/图片/视频/文件失败，没有配置OSS存储，详情参考：https://docs.goeasy.io/2.x/im/message/media/alioss'
								);
						} else {
							console.log('发送失败:', error);
						}
					}
				});
			},
			scrollToBottom() {
				this.$nextTick(() => {
					this.$refs.scrollView.scrollTop = this.$refs.messageList.scrollHeight;
				})
			}
		}

	};
</script>

<style scoped>
	.chat-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.chat-title {
		height: 40px;
		padding: 0 15px;
		display: flex;
		align-items: center;
		font-size: 18px;
	}

	.chat-avatar {
		width: 35px;
		height: 35px;
		border-radius: 50%;
	}

	.chat-name {
		width: 400px;
		margin-left: 10px;
		font-size: 15px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;
	}

	.chat-main {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		flex: 1;
		scrollbar-width: thin;
	}

	.chat-main::-webkit-scrollbar {
		width: 0;
	}

	.chat-main .message-list {
		padding: 0 15px;
	}

	.chat-main .history-loaded {
		text-align: center;
		font-size: 12px;
		color: #cccccc;
		cursor: pointer;
		line-height: 20px;
	}

	.chat-main .load {
		text-align: center;
		font-size: 12px;
		color: #d02129;
		line-height: 20px;
		cursor: pointer;
	}

	.history-loading {
		width: 100%;
		text-align: center;
	}

	.time-tips {
		color: #999;
		text-align: center;
		font-size: 12px;
	}

	.message-list {
		padding: 0 10px;
	}

	.message-item {
		display: flex;
	}

	.accept-message {
		width: 100%;
		text-align: center;
		color: #606164;
		line-height: 25px;
	}

	.message-item-content {
		flex: 1;
		margin: 5px 0;
		overflow: hidden;
		display: flex;
	}

	.sender-info {
		margin: 0 5px;
	}

	.sender-avatar {
		width: 35px;
		height: 35px;
		border-radius: 50%;
	}

	.sender-name {
		color: #606164;
		text-align: center;
	}

	.message-content {
		max-width: calc(100% - 100px);
	}

	.message-payload {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
	}

	.pending {
		background: url("../assets/images/pending.gif") no-repeat center;
		background-size: 13px;
		width: 15px;
		height: 15px;
	}

	.send-fail {
		background: url("../assets/images/failed.png") no-repeat center;
		background-size: 13px;
		width: 15px;
		height: 15px;
	}

	.content-text {
		display: flex;
		align-items: center;
		text-align: left;
		background: #eeeeee;
		font-size: 14px;
		font-weight: 500;
		padding: 6px 8px;
		margin: 3px 0;
		line-height: 25px;
		white-space: pre-line;
		overflow-wrap: anywhere;
		border-radius: 8px;
		word-break: break-all;
		flex-wrap: wrap;
	}

	.content-image {
		display: block;
		cursor: pointer;
	}

	.content-image img {
		height: 100%;
	}

	.content-audio {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.content-audio .audio-facade {
		min-width: 12px;
		background: #eeeeee;
		border-radius: 7px;
		display: flex;
		font-size: 14px;
		padding: 8px;
		margin: 5px 0;
		line-height: 25px;
		cursor: pointer;
	}

	.content-audio .audio-facade-bg {
		background: url("../assets/images/voice.png") no-repeat center;
		background-size: 15px;
		width: 20px;
	}

	.content-audio .audio-facade-bg.play-icon {
		background: url("../assets/images/play.gif") no-repeat center;
		background-size: 20px;
	}

	.content-order {
		border-radius: 5px;
		border: 1px solid #eeeeee;
		padding: 8px;
		display: flex;
		flex-direction: column;
	}

	.content-order .order-id {
		font-size: 12px;
		color: #666666;
		margin-bottom: 5px;
	}

	.content-order .order-body {
		display: flex;
		font-size: 13px;
		padding: 5px;
	}

	.content-order .order-img {
		width: 70px;
		height: 70px;
		border-radius: 5px;
	}

	.content-order .order-name {
		margin-left: 10px;
		width: 135px;
		color: #606164;
	}

	.content-order .order-count {
		font-size: 12px;
		color: #666666;
		flex: 1;
	}

	.message-item .self {
		overflow: hidden;
		display: flex;
		justify-content: flex-start;
		flex-direction: row-reverse;
	}

	.message-item .self::v-deep(.play-icon) {
		background: url("../assets/images/play.gif") no-repeat center;
		background-size: 20px;
		-moz-transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}

	.chat-footer {
		border-top: 1px solid #dcdfe6;
		width: 100%;
		height: 140px;
		background: #FFFFFF;
	}

	.action-box {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.action-bar {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.action-bar .iconfont {
		font-size: 22px;
		margin: 0 10px;
		z-index: 3;
		color: #606266;
		cursor: pointer;
	}

	.action-bar .iconfont:focus {
		outline: none;
	}

	.action-bar .iconfont:hover {
		color: #d02129;
	}

	.chat-action {
		display: flex;
		flex-direction: row;
		padding: 0 10px;
	}

	.action-bar .action-item {
		text-align: left;
		padding: 10px 0;
		position: relative;
	}

	.emoji-box {
		width: 515px;
		position: absolute;
		top: -111px;
		left: -11px;
		z-index: 2007;
		background: #fff;
		border: 1px solid #ebeef5;
		padding: 12px;
		text-align: justify;
		font-size: 14px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		word-break: break-all;
		border-radius: 4px;
	}

	.emoji-item {
		width: 38px;
		height: 38px;
		margin: 0 2px;
	}

	.session-action {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	.session-action .transfer {
		cursor: pointer;
		margin-right: 10px;
		line-height: 30px;
		text-align: center;
		width: 70px;
		height: 30px;
		font-size: 15px;
		border: 1px solid #d02129;
		background-color: #ffffff;
		color: #d02129;
		border-radius: 5px;
	}

	.input-box {
		flex: 1;
		padding: 5px 15px;
	}

	.input-content {
		border: none;
		resize: none;
		display: block;
		width: 90%;
		color: #606266;
		outline: none;
		background: #FFFFFF;
		word-break: break-all;
	}

	.send-box {
		padding: 5px 10px;
		text-align: right;
	}

	.send-button {
		width: 70px;
		height: 30px;
		font-size: 15px;
		border: 1px solid #d02129;
		background-color: #ffffff;
		color: #d02129;
		border-radius: 5px;
		cursor: pointer;
	}

	.accept-session {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.accept-info {
		font-size: 18px;
		color: #d02129;
		margin-bottom: 10px;
	}

	.accept-btn {
		width: 75px;
		height: 30px;
		font-size: 15px;
		border: 1px solid #d02129;
		background-color: #ffffff;
		color: #d02129;
		border-radius: 5px;
		cursor: pointer;
	}

	.image-preview {
		max-width: 750px;
		max-height: 500px;
		background: rgba(0, 0, 0, 0.8);
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 9998;
	}

	.image-preview img {
		max-width: 750px;
		max-height: 500px;
	}

	.image-preview .close {
		font-size: 50px;
		line-height: 24px;
		cursor: pointer;
		color: #FFFFFF;
		position: absolute;
		top: 10px;
		right: 5px;
		z-index: 1002;
	}

	.transfer-popup {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(33, 33, 33, 0.7);
	}

	.transfer-model {
		width: 450px;
		min-height: 200px;
		display: flex;
		flex-direction: column;
		padding: 5px;
		border: 1px solid gainsboro;
		border-radius: 4px;
		text-align: center;
		background: #ffffff;
	}

	.transfer-content {
		flex: 1;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.no-agent {
		flex: 1;
	}

	.agent-info {
		width: 110px;
		padding: 20px;
	}

	.agent-label {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.agent-avatar {
		width: 35px;
		height: 35px;
		margin: 0 5px;
	}

	.agent-name {
		font-size: 14px;
		word-break: break-all;
	}

	.transfer-bottom {
		height: 50px;
		display: flex;
		margin: 0 50px;
		align-items: center;
		justify-content: space-around;
	}

	.transfer-button {
		display: inline-block;
		padding: 8px 15px;
		font-size: 13px;
		border: 1px solid #d02129;
		color: #d02129;
		border-radius: 4px;
		cursor: pointer;
	}

	.order-box {
		width: 848px;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 2007;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(33, 33, 33, 0.7);
	}

	.order-list {
		width: 300px;
		background: #F1F1F1;
		border-radius: 5px;
	}

	.order-list .title {
		font-weight: 600;
		font-size: 15px;
		color: #000000;
		margin-left: 10px;
		margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.order-list .title span {
		font-size: 28px;
		font-weight: 400;
		cursor: pointer;
	}

	.order-list .order-item {
		padding: 10px;
		background: #FFFFFF;
		margin: 10px;
		border-radius: 5px;
		cursor: pointer;
	}

	.order-list .order-id {
		font-size: 12px;
		color: #666666;
		margin-bottom: 5px;
	}

	.order-list .order-body {
		display: flex;
		font-size: 13px;
		justify-content: space-between;
	}

	.order-list .order-img {
		width: 50px;
		height: 50px;
		border-radius: 5px;
	}

	.order-list .order-name {
		width: 160px;
	}

	.order-list .order-count {
		font-size: 12px;
		color: #666666;
		flex: 1;
	}
</style>